import styled from 'styled-components'

export const StyledButtonContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-top: -35px;
  font-size: 14px;
  font-weight: 600;
`
