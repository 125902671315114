import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'
import Pagination from '@material-ui/lab/Pagination'
import closeIcon from 'assets/images/icons/closeInput.svg'

export const TableStyles = styled.div<any>`
  width: 100%;
  height: 93%;
  ${respondFrom.lessThanTablet`
    overflow-x: scroll;
  `}
  table {
    border-spacing: 0;
    overflow: visible;
    border-spacing: 2px;
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    thead {
      display: table-header-group;
    }
    tbody {
      display: block;
      height: ${(props) => (props.tableError ? '0px' : '360px')};
    }
    tr {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: 65px;
    }
    th,
    td {
      position: relative;
      margin: 0;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      border-bottom-color: rgb(224, 224, 224);
      border-radius: 4px 4px 0px 0px;
      border-right: 10px solid #fff;
      padding: 0;
      text-align: left;
      align-items: center;
      height: 50px;
    }
    th {
      height: 50px;
      vertical-align: bottom;
      div,
      span {
        color: #4a4a49;
        font-weight: 400;
        margin-bottom: 10px;
        margin-right: 5px;
        display: inline-block;
      }
    }
    td {
      text-align: left;
    }
    th:nth-child(1),
    td:nth-child(1) {
      width: 20%;
    }
    th:nth-child(2),
    td:nth-child(2) {
      width: 30%;
      min-width: 250px;
      max-width: 500px;
      border-right: 0;
    }
    th:nth-child(3),
    td:nth-child(3) {
      width: 40%;
      border-right: 0;
    }
    th:nth-child(4),
    td:nth-child(4) {
      width: 20%;
      text-align: center;
      border-right: 0;
    }
  }
`

export const StyledColumn = styled.td<any>`
  background-color: #ffffff;
`

export const StyledNameContainer = styled.div<any>`
  width: 100%;
  span {
    font-size: 16px;
    display: inline-block;
    text-overflow: ellipsis;
    width: 90%;
    overflow: hidden;
  }
`

export const StyledAmountContainer = styled.div<any>`
  width: 100%;
  span {
    font-size: 16px;
    display: inline-block;
    width: 100%;
    color: ${(props) => (props.green ? '#5CB814' : '#A93C30')};
    text-align: end;
    padding-right: 40px;
    font-weight: 600;
  }
`

export const StyledPagination = styled(Pagination)<any>`
  margin-top: 10px;
`

export const StyledMenuContainer = styled.div<any>`
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`

export const StyledTimePickers = styled.div<any>`
  display: flex;
  gap: 10px;
`

export const StyledCoinAmountHeader = styled.div<any>`
  display: flex !important;
  align-items: end;
  img {
    height: 24px;
    margin-right: 5px;
  }
  span {
    margin-bottom: 0px !important;
  }
`

export const ClearIcon = styled.div`
  content: '';
  height: 20px;
  width: 20px;
  background-image: url(${closeIcon});
  background-size: cover;
  position: relative;
  border: 0;
  top: 5px;
  right: 35px;
  cursor: pointer;
`
