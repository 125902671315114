import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  StyledTimePickers,
  StyledNameContainer,
  StyledPagination,
  TableStyles,
  StyledMenuContainer,
  StyledAmountContainer,
  StyledCoinAmountHeader,
  ClearIcon,
} from './CoinHistoryTable.style'
import { useGetCoinHistoryListQuery, useLazyGetCoinHistoryListFullQuery } from 'services/coins-api'
import CoinHistoryTableComponent from './CoinHistoryTableComponent'
import DateCell from './DateCell/DateCell'
import moment, { Moment } from 'moment'
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import MomentUtils from '@date-io/moment'
import 'moment/locale/de'
import Button from 'components/Button/Button'
import coinIcon from 'assets/images/icons/coin.svg'
import { generateCoinsHistoryCSV } from 'common/utils/CSVGenerator'

const CoinHistoryTable = () => {
  const { t, i18n } = useTranslation()
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [from, setFrom] = React.useState<Moment | null>(null)
  const [to, setTo] = React.useState<Moment | null>(null)
  const [isDownloadLoading, setDownloadLoading] = useState(false)
  const dateFormat = 'YYYY-MM-DD HH:mm:ss.SSS'

  const { data } = useGetCoinHistoryListQuery({
    page: currentPage,
    from: from ? from.format(dateFormat) : null,
    to: to ? to.format(dateFormat) : null,
  })

  const [getCoinHistoryListFull] = useLazyGetCoinHistoryListFullQuery()

  const coinHsitoryData = useMemo(() => {
    return data?.content || []
  }, [data])

  const handleDownloadClickButton = async () => {
    setDownloadLoading(true)
    const coinHistoryDataFull = await getCoinHistoryListFull({
      from: from ? from.format(dateFormat) : null,
      to: to ? to.format(dateFormat) : null,
    })
    generateCoinsHistoryCSV(coinHistoryDataFull.data, t, moment().format('YYYY-MM-DDTHH:mm:ss'))
    setDownloadLoading(false)
  }

  const columns = [
    {
      Header: () => {
        return <span>{t('coinHistoryList.headers.date')}</span>
      },
      accessor: 'transactionDate',
      columns: [
        {
          accessor: 'transactionDate',
          Cell: (props: any) => {
            return <DateCell date={props.cell.row.original.transactionDate} />
          },
        },
      ],
    },
    {
      Header: () => {
        return <span>{t('coinHistoryList.headers.type')}</span>
      },
      accessor: 'type',
      columns: [
        {
          accessor: 'type',
          Cell: (props: any) => {
            return (
              <StyledNameContainer>
                <span>
                  {t(`coinHistoryList.type.${props.cell.row.original.origin.toLowerCase()}`)}
                </span>
              </StyledNameContainer>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return <span>{t('coinHistoryList.headers.product')}</span>
      },
      accessor: 'product',
      columns: [
        {
          accessor: 'product',
          Cell: (props: any) => {
            return (
              <StyledNameContainer>
                <span>
                  {props.cell.row.original.product
                    ? t(
                        `coinHistoryList.product.${props.cell.row.original.product?.toLowerCase()}`,
                        props.cell.row.original.product
                      )
                    : '-'}
                </span>
              </StyledNameContainer>
            )
          },
        },
      ],
    },
    {
      Header: () => {
        return (
          <StyledCoinAmountHeader>
            <img src={coinIcon} alt="coinIcon" />
            <span>{t('coinHistoryList.headers.amount')}</span>
          </StyledCoinAmountHeader>
        )
      },
      accessor: 'delta',
      columns: [
        {
          accessor: 'delta',
          Cell: (props: any) => {
            return (
              <StyledAmountContainer green={props.cell.row.original.delta > 0}>
                <span>
                  {props.cell.row.original.delta > 0 ? '+' : ''}
                  {props.cell.row.original.delta}
                </span>
              </StyledAmountContainer>
            )
          },
        },
      ],
    },
  ]

  return (
    <>
      <StyledMenuContainer>
        <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
          <StyledTimePickers>
            <DateTimePicker
              variant="inline"
              inputVariant="outlined"
              label={t('coinHistoryList.datePicker.from')}
              value={from}
              onChange={setFrom}
              emptyLabel=" "
              ampm={false}
            />
            {from && <ClearIcon onClick={() => setFrom(null)} />}
            <DateTimePicker
              variant="inline"
              inputVariant="outlined"
              label={t('coinHistoryList.datePicker.to')}
              value={to}
              onChange={setTo}
              emptyLabel=" "
              ampm={false}
            />
            {to && <ClearIcon onClick={() => setTo(null)} />}
          </StyledTimePickers>
        </MuiPickersUtilsProvider>
        <Button
          kind="secondary"
          onClick={handleDownloadClickButton}
          loading={isDownloadLoading}
          active={isDownloadLoading}
        >
          {t('coinHistoryList.downloadCSV')}
        </Button>
      </StyledMenuContainer>
      <TableStyles>
        <CoinHistoryTableComponent columns={columns} data={coinHsitoryData} />
        <StyledPagination
          count={data?.totalPages}
          page={currentPage}
          onChange={(e, newPage) => setCurrentPage(newPage)}
        />
      </TableStyles>
    </>
  )
}

export default CoinHistoryTable
