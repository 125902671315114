import React from 'react'
import { StyledDate } from './DateCell.style'
import dayjs from 'dayjs'

const DateCell = ({ date }: { date: string }) => {
  const parsed = dayjs(date, 'YYYY-MM-DD HH:mm:ss.SSS')
  const dayMonthYear = parsed.format('DD.MM.YYYY')
  const time = parsed.format('hh:mm')

  return (
    <StyledDate>
      <div>{dayMonthYear}</div>
      <div className="time">{time}</div>
    </StyledDate>
  )
}

export default DateCell
