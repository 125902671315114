import { respondFrom, respondTo } from 'common/styles/Responsive.styles'
import Button from 'components/Button/Button'
import { handleButtonBackground } from 'components/Button/Button.style'
import styled from 'styled-components'

export const StyledNav = styled.div`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 99;
  background-color: #fff;
`

export const StyledNavItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  & + & {
    margin: 10px 0 0;
  }
  ${respondTo.lessThanTablet`
    & + & {
      margin: 0 0 0 20px;
    }
  `}
  ${respondFrom.lessThanTablet`
    & > img {
      display: none;
    }
  `}
`

export const StyledContainer = styled.div`
  max-width: 1200px;
  height: 90px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid rgba(124, 124, 123, 0.2);
  ${respondFrom.lessThanTablet`
    flex-direction: column;
  `}
`
export const StyledSpanItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  color: #0b4f98;
  cursor: pointer;
  span {
    margin-right: 8px;
    color: inherit;
  }
  & + & {
    color: #495057;
  }
  :hover {
    > div {
      display: flex;
    }
  }
`

export const StyledImmopointsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  gap: 10px;
  margin-left: 20px;
  .value-icon {
    width: 40px;
  }
  span {
    font-size: 15px;
    font-weight: bold;
    color: #0b4f98;
  }
  :hover {
    > div {
      display: flex;
    }
  }
`

export const StyledNavItem = styled.div`
  margin-left: 20px;
  display: flex;
  align-items: center;
  color: #0b4f98;
  white-space: nowrap;

  span {
    font-size: 15px;
  }

  img {
    width: 30px;
  }

  &:first-of-type {
    margin-right: auto;
    margin-left: 0;
  }
  .reset-transform {
    text-transform: none;
  }

  .disabled-coins-button {
    background-color: ${handleButtonBackground('orange')};
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;

    :hover {
      cursor: default;
      color: #ffffff;
      background-color: ${handleButtonBackground('tertiary')};
    }
  }

  .immopoints-button {
    background-color: #333333;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;

    :hover {
      color: #ffffff;
      background-color: #474747;
    }
  }

  .disabled-immopoints-button {
    background-color: #858585;
    color: #ffffff;
    font-size: 14px;
    font-weight: 600;

    :hover {
      cursor: default;
      background-color: #474747;
    }
  }
`
export const StyledArrow = styled.div`
  margin-left: 30px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: #0b4f98;

  img {
    width: 30px;
  }
  &:first-of-type {
    margin-right: auto;
    margin-left: 0;
  }
  .reset-transform {
    text-transform: none;
  }
`

export const StyledTitle = styled.div`
  font-weight: 400;
  font-size: 24px;
  color: #495057;

  ${respondFrom.lessThanTablet`
    font-size: 20px;
  `}
`

export const StyledNavLinkContainer = styled.div`
  text-decoration: none;
  font-size: 12px;
  color: ${({ isActive }: any) => (isActive ? '#1f497d' : '#495057')};
  transition: opacity 0.2s;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  a:hover {
    opacity: 0.7;
  }
  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: inherit;
    text-decoration: none;
  }
  path {
    stroke: ${({ isActive }: any) => (isActive ? '#1f497d' : '#495057')};
  }
`

export const StyledNavImage = styled.img`
  transform: scale(0.75);
`

export const StyledCoinsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const StyledCoinsInformationDropdown = styled.div`
  display: none;
  flex-direction: column;
  position: absolute;
  top: ${(props) => (props.isImmopoints ? '60%' : '100%')};
  min-width: 280px;
  max-width: 365px;
  color: #495057;
  background-color: #fff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  padding: 10px 20px;
  font-weight: 400;
`

export const StyledCoinItem = styled.div`
  display: flex;
  padding: 10px 0;
  span {
    color: #0b4f98;
    margin-left: auto;
    margin-right: 0;
    font-weight: 600;
  }
  &:not(:first-child) {
    border-top: 1px solid #d8d8d7;
  }
`

export const StyledCoinItemHelper = styled.div`
  font-size: 12px;
  display: flex;
  margin-top: -6px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  span {
    font-size: inherit;
    margin-left: 5px;
    margin-right: 0;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  svg {
    width: 14px;
    height: 14px;
    fill: #c8cbcd;
    margin-right: 5px;
  }
`

export const StyledCoinsError = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #495057;
  width: 330px;
  text-wrap: pretty;
  text-align: center;
  padding-bottom: 20px;

  h1 {
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
  }

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
`

export const StyledWarningIcon = styled.div`
  display: flex;
  margin-right: 10px;
  path {
    stroke: #f18500;
  }
  circle {
    stroke: #f18500;
  }
  line {
    stroke: #f18500;
  }
`

export const StyledTryAgainButton = styled(Button)<any>`
  min-width: 100px;
  height: 26px;
`

export const StyledCoinHistoryButton = styled(Button)<any>`
  min-width: 100px;
  height: 26px;
`

export const StyledCoinHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 1px solid #d8d8d7;
  padding: 20px;
`
