import React, { useState } from 'react'
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles'
import { default as MaterialModal } from '@material-ui/core/Modal'
import ModalTop from '../ModalTop/ModalTop'
import { useTranslation } from 'react-i18next'
import CoinHistoryTable from 'components/CoinHistoryTable/CoinHistoryTable'
import { StyledButtonContainer } from './CoinHistoryModal.styled'
import Button from 'components/Button/Button'

function getModalStyle() {
  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      maxWidth: 720,
      width: '100%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      borderRadius: 4,
    },
  })
)

function CoinHistoryModal({ isOpened, onBackdropClick }: any): any {
  const classes = useStyles()
  const { t } = useTranslation()
  const [modalStyle] = useState(getModalStyle)

  return (
    <MaterialModal
      open={isOpened}
      disableBackdropClick={true}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      disablePortal
      style={{ height: '750px' }}
    >
      <>
        <div style={modalStyle} className={classes.paper}>
          <ModalTop label={t('coinHistoryModal.title')} onBackdropClick={onBackdropClick} />
          <CoinHistoryTable />
          <StyledButtonContainer>
            <Button onClick={onBackdropClick}>{t('common.closeButton')}</Button>
          </StyledButtonContainer>
        </div>
      </>
    </MaterialModal>
  )
}

export default CoinHistoryModal
