import styled from 'styled-components'

const handleToastColor = (type) => {
  switch (type) {
    case 'info':
      return '#495057'
    case 'success':
      return '#5CB814'
    case 'error':
      return '#D82815'
    default:
      return '#495057'
  }
}

export const StyledToastBody = styled.div`
  position: absolute;
  z-index: 99999;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 100ms;
  bottom: 1rem;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
  justify-content: center;
  transform: translateX(-50%);
  left: 50%;
`

export const StyledToastContent = styled.div`
  max-height: 80px;
  padding: 8px 12px 8px 12px;
  justify-content: space-between;
  color: #ffffff;
  border-radius: 2px;
  display: flex;
  align-items: center;
  max-width: 800px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  background-color: ${({ type }) => handleToastColor(type)};
  width: fit-content;
`

export const StyledErrorIcon = styled.div`
  display: inline-flex;
  path {
    stroke: #ffffff;
  }
  circle {
    stroke: #ffffff;
  }
  line {
    stroke: #ffffff;
  }
`

export const StyledCloseButton = styled.div`
  display: inline-flex;
  margin-left: 12px;
  :hover {
    cursor: pointer;
  }
`
