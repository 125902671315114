import { respondFrom } from 'common/styles/Responsive.styles'
import styled from 'styled-components'

export const StyledShortButton = styled.div`
  border-radius: 18px;
  background-color: ${({ isDisabled }: any) => (isDisabled ? '#dfdfdf' : '#F9F9F9')};
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  position: relative;
  width: calc(50% - 10px);
  gap: 8px;

  ${respondFrom.lessThanTablet`
    width: calc(100% - 10px);
  `}

  > a, > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: stretch;
    gap: 18px;
    border-radius: 18px;
    width: 100%;
    height: 100%;
    text-decoration: none;
  }

  a:hover:not(:has(a:hover)):not(.more-info) {
    background-color: ${({ hoverColors, isDisabled }: any) =>
      isDisabled ? '#dfdfdf' : hoverColors || '#d6eaff'};
  }
`

export const StyledProductIcon = styled.div`
  position: relative;
  display: inline-block;
  height: 60px;
  width: 60px;
  > img {
    height: 60px;
    width: 60px;
  }
`

export const StyledProductInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px 10px 14px;
  gap: 18px;
  width: 100%;
  height: 100%;
`

export const StyledProductDescription = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 2px;
  overflow-wrap: anywhere;
  height: 100%;
`

export const StyledProductTitle = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: #495057;
  > img {
    margin-left: 8px;
    height: 20px;
    width: 20px;
  }
`

export const StyledProductSubtitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #7c7c7b;
`

export const StyledRightContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-left: auto;
`

export const StyledTopRightContent = styled.div`
  display: flex;
  padding: 8px 8px 0px 0px;
  margin-left: auto;
  height: 36px;

  > img {
    height: 28px;
  }
`

export const StyledBottomRightContent = styled.div`
  color: #7c7c7b;
  padding: 0px 8px 8px 0px;
  margin-top: auto;
  a {
    display: block;
    height: 37px;
    width: 148px;
    padding: 6px 0px 6px 0px;
    border-radius: 16px;
    background-color: #ffffff;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: inherit;
    align-content: center;

    :hover {
      background-color: #ededed;
    }
  }
`

export const StyledHomeIcon = styled.div`
  padding: 0px 12px 0px 12px;
  display: flex;
  align-content: center;
  flex-wrap: wrap;

  img {
    height: 28px;
    width: 28px;
  }
`

export const StyledCoinPrice = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 12px;
  gap: 8px;

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    color: ${(props) => (props.isImmopoints ? '#333333' : '#1f497d')};
  }

  img {
    height: 20px;
    width: ${(props) => (props.isImmopoints ? '35px' : '20px')};
  }
`

export const StyledCheckmarkIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 12px;

  circle {
    fill: #5cb814;
  }
`

export const StyledCartIcon = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 12px 0px 12px;
  img {
    height: 20px;
    width: 20px;
  }
`
