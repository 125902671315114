import { CoinHistoryType } from 'types/ApiResponse'

const generateCSV = (data, fileName) => {
  const link = document.createElement('a')
  const blob = new Blob(['\uFEFF' + data], { type: 'text/csv;charset=UTF-8;' })
  const url = URL.createObjectURL(blob)

  link.setAttribute('href', url)
  link.setAttribute('download', `${fileName}.csv`)
  link.style.visibility = 'hidden'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const generateLeadCSV = (lead, isRow?) => {
  const { id, ...restLeadProps } = lead
  const headers = Object.keys(restLeadProps)
  const csv = []

  headers.map((header) =>
    lead[header] === null || lead[header] === undefined ? (lead[header] = '') : ''
  )

  if (!isRow) {
    for (const [key, value] of Object.entries(lead)) {
      csv.push(`${key}, ${value}`)
    }
  } else {
    csv.push(headers.join(','))
    csv.push(headers.map((header) => JSON.stringify(lead[header])).join(','))
  }

  generateCSV(csv.join('\r\n'), `${lead.street}-${lead.zip}-${lead.town}-${lead.nation}`)
}

export const generateIndicesCSV = (indicesData, zip, town) => {
  const csv = []
  const headers = [
    'year',
    'month',
    'quarter',
    'index',
    'scale',
    'region',
    'object type',
    'type',
    'date',
  ]

  csv.push(headers.join(';'))
  for (const indicesRow of indicesData.values) {
    const indexValue = indicesRow.index.toLocaleString('de-DE', {
      useGrouping: false,
    })
    csv.push(
      `${indicesRow.year};${indicesRow.month};${indicesRow.quarter};${indexValue};${
        indicesRow.scale
      };${indicesRow.region};${indicesData.objectCategory};${indicesData.type.toLowerCase()};${
        indicesData.date
      }`
    )
  }

  generateCSV(csv.join('\r\n'), `indices_${zip}-${town}`)
}

const getCoinHistoryValue = (value, translatedValue = null) => {
  if (value == null) return ''
  if (translatedValue == null) return value
  return translatedValue
}

export const generateCoinsHistoryCSV = (coinsHistoryData: CoinHistoryType[], t, date: string) => {
  const csv = []
  const headers = [
    t('coinHistoryList.headers.date'),
    t('coinHistoryList.headers.type'),
    t('coinHistoryList.headers.product'),
    t('coinHistoryList.headers.amount'),
  ]

  csv.push(headers.join(';'))
  for (const coinHistoryElement of coinsHistoryData) {
    csv.push(
      `${getCoinHistoryValue(coinHistoryElement.transactionDate)};${getCoinHistoryValue(
        coinHistoryElement.origin,
        t(`coinHistoryList.type.${coinHistoryElement.origin.toLowerCase()}`)
      )};${getCoinHistoryValue(
        coinHistoryElement.product,
        t(
          `coinHistoryList.product.${coinHistoryElement.product?.toLowerCase()}`,
          coinHistoryElement.product
        )
      )};${getCoinHistoryValue(coinHistoryElement.delta)}`
    )
  }
  generateCSV(csv.join('\r\n'), `coins-history_${date}`)
}
