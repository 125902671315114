import React, { useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
  StyledCartIcon,
  StyledProductIcon,
  StyledShortButton,
  StyledProductInfo,
  StyledProductDescription,
  StyledProductTitle,
  StyledProductSubtitle,
  StyledRightContent,
  StyledTopRightContent,
  StyledBottomRightContent,
  StyledCoinPrice,
  StyledHomeIcon,
  StyledCheckmarkIcon,
} from './ProductListTile.style'
import Link from 'components/Link/Link'
import addressListIcon from 'assets/images/icons/addressList.svg'
import batchListIcon from 'assets/images/icons/batchList.svg'
import line from 'assets/images/icons/line.svg'
import coinIcon from 'assets/images/icons/coin.svg'
import { ReactComponent as CheckmarkIcon } from 'assets/images/icons/radio-checked.svg'
import cartIcon from 'assets/images/icons/shopcart.svg'
import immopointsIcon from 'assets/images/icons/immoPoints.svg'
import { useTranslation } from 'react-i18next'
import starFilledIcon from 'assets/images/icons/starFilled.svg'
import starEmptyIcon from 'assets/images/icons/starEmpty.svg'
import Tooltip from '@material-ui/core/Tooltip'

const ProductLink = (props: any) => {
  const { product, customerConfig, children } = props
  const isHome = product.isAddressList || product.isBatchList
  if (!isHome || (isHome && product.showCartIcon)) {
    return (
      <Link
        url={product.showCartIcon ? product?.urlMarketing : product?.url}
        newTab={product?.newTab}
        channelName={customerConfig?.name}
        isDisabled={product?.isDisabled}
      >
        {children}
      </Link>
    )
  }
  if (isHome && !product.showCartIcon) {
    const link = product.isAddressList ? '/leads' : '/batches'
    return <RouterLink to={link}>{children}</RouterLink>
  }
  return <></>
}

const ProductListTile = (props: any) => {
  const { t } = useTranslation()
  const { product, customerConfig, icon, favoritesEnabled, favoritesList, saveFavorites } = props
  const isHome = product.isAddressList || product.isBatchList
  const [starHover, setStarHover] = useState(false)
  const priceIcon = customerConfig?.immopointsEnabled !== true ? coinIcon : immopointsIcon

  const onFavoriteClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    const newFavorites = favoritesList.includes(product.id)
      ? favoritesList.filter((id: number) => id !== product.id)
      : favoritesList.concat([product.id])
    saveFavorites(newFavorites)
  }

  return (
    <StyledShortButton
      hoverColors={product.hoverColor}
      isDisabled={product?.isDisabled && !product.isAddressList}
    >
      <ProductLink product={product} customerConfig={customerConfig}>
        <StyledProductInfo>
          <StyledProductIcon>
            <img src={product?.imageUrl || icon} alt={product.name} />
          </StyledProductIcon>
          <StyledProductDescription>
            <StyledProductTitle>
              <span>{t(`products.${product.name.toLowerCase().replace(/\s+/g, '')}.title`)}</span>
              {favoritesEnabled && (
                <Tooltip
                  title={
                    favoritesList.includes(product.id)
                      ? t('common.remove-favorite')
                      : t('common.add-favorite')
                  }
                  arrow
                  placement="top"
                >
                  <img
                    src={
                      starHover
                        ? starFilledIcon
                        : favoritesList.includes(product.id)
                        ? starFilledIcon
                        : starEmptyIcon
                    }
                    onMouseOver={() => setStarHover(true)}
                    onMouseOut={() => setStarHover(false)}
                    alt=""
                    onClick={(event) => onFavoriteClick(event)}
                  />
                </Tooltip>
              )}
            </StyledProductTitle>
            <StyledProductSubtitle>
              {t(`products.${product.name.toLowerCase().replace(/\s+/g, '')}.description`)}
            </StyledProductSubtitle>
          </StyledProductDescription>
        </StyledProductInfo>
        <StyledRightContent>
          <StyledTopRightContent>
            {isHome && (
              <StyledHomeIcon>
                <img src={product?.isAddressList ? addressListIcon : batchListIcon} alt="" />
              </StyledHomeIcon>
            )}
            {((product.coinPrice > 0 && product.coinBased) ||
              product.showCheckmarkIcon ||
              product.showCartIcon) &&
              isHome && <img src={line} className="separator" alt="" />}
            {!product.showCheckmarkIcon && product.coinBased && product.coinPrice > 0 && (
              <StyledCoinPrice isImmopoints={customerConfig?.immopointsEnabled === true}>
                <img src={priceIcon} alt="coinIcon" />
                <span>{product.coinPrice}</span>
              </StyledCoinPrice>
            )}
            {product.showCheckmarkIcon && (
              <StyledCheckmarkIcon>
                <CheckmarkIcon />
              </StyledCheckmarkIcon>
            )}
            {product.showCartIcon && (
              <StyledCartIcon>
                <img src={cartIcon} alt="cart.svg" />
              </StyledCartIcon>
            )}
          </StyledTopRightContent>
          <StyledBottomRightContent>
            {(product?.urlMarketing?.length && (
              <Link
                url={product?.urlMarketing}
                newTab={product?.newTab}
                channelName={customerConfig?.name}
                className="more-info"
                stopPropagation={true}
              >
                {t(product.showCartIcon ? 'common.checkOffer' : 'common.moreInfo')}
              </Link>
            )) ||
              null}
          </StyledBottomRightContent>
        </StyledRightContent>
      </ProductLink>
    </StyledShortButton>
  )
}

export default ProductListTile
