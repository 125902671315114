import { createApi } from '@reduxjs/toolkit/query/react'
import { baseQueryWithSessionEnd } from './common'
import {
  CoinHistoryListApiResponseType,
  CoinHistoryListFullApiResponseType,
  CustomerCoinsEntityType,
} from 'types/ApiResponse'

const COINS = '/coins'

export const coinsApi = createApi({
  reducerPath: 'coinsApi',
  refetchOnMountOrArgChange: true,
  baseQuery: baseQueryWithSessionEnd,

  endpoints: (builder) => ({
    getProductCost: builder.query<Record<string, number>, string>({
      query: (product) => ({
        url: `${COINS}/${product}`,
        method: 'GET',
      }),
    }),
    getProductsCost: builder.query<Record<string, number>, string[]>({
      query: (products) => ({
        url: `${COINS}/products/${products.join()}`,
        method: 'GET',
      }),
    }),
    getCustomerCoins: builder.query<CustomerCoinsEntityType, void>({
      query: () => ({
        url: `/customer/V2/coins`,
        method: 'GET',
      }),
    }),
    getCoinHistoryList: builder.query<
      CoinHistoryListApiResponseType,
      { page: number; from?: string; to?: string; size?: number }
    >({
      query: ({ page, from, to, size = 5 }) => {
        let url = `/customer/coin-history?page=${page - 1}&size=${size}`
        if (from != null) url = url + `&from=${from}`
        if (to != null) url = url + `&to=${to}`
        return url
      },
    }),
    getCoinHistoryListFull: builder.query<
      CoinHistoryListFullApiResponseType,
      { from?: string; to?: string }
    >({
      query: ({ from, to }) => {
        let url = `/customer/coin-history/full`
        if (from != null) url = url + `?from=${from}`
        if (to != null) url = url + `${from != null ? '&' : '?'}to=${to}`
        return url
      },
    }),
  }),
})

export const {
  useGetProductCostQuery,
  useGetProductsCostQuery,
  useGetCustomerCoinsQuery,
  useLazyGetCustomerCoinsQuery,
  useGetCoinHistoryListQuery,
  useLazyGetCoinHistoryListFullQuery,
} = coinsApi
